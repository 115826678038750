/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }

    &:-moz-placeholder {
        @content
    }

    &::-moz-placeholder {
        @content
    }

    &:-ms-input-placeholder {
        @content
    }
}

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&family=Jost:wght@300;400;500&display=swap');

/*-- Common Style --*/
*,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.bg-green {
    background-color: $secondary-2;
}

.lucide {
    stroke-width: 1px;
}

body {
    font-size: 14px;
    line-height: 1.75;
    font-weight: 400;
    font-family: $body-font;
    color: $body-color;
    outline: none;
    visibility: visible;
    overflow-X: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: "";

    &[dir="rtl"] {
        text-align: right;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font;
    color: $heading-color;
    font-weight: 700;
    margin-top: 0;
    line-height: 1.5;
    margin-bottom: 0;
}

h1 {
    font-size: 36px;

    // Responisve
    @media #{$large-mobile} {
        font-size: 30px;
    }
}

h2 {
    font-size: 30px;

    // Responisve
    @media #{$large-mobile} {
        font-size: 24px;
    }
}

h3 {
    font-size: 24px;

    // Responisve
    @media #{$large-mobile} {
        font-size: 20px;
    }
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 16px;
}

h6 {
    font-size: 14px;
}

p {
    margin-bottom: 0;
}

a,
button {
    color: inherit;
    display: inline-block;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
}

a,
button,
img,
input {
    transition: $transition-default;
    outline: 0;

    &:focus {
        outline: 0;
    }
}

a:focus {
    color: none;
    outline: none;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    color: $primary;
}

ul,
ol {
    padding: 0;
    list-style: none;
    margin: 0;
}

button,
input[type="submit"] {
    cursor: pointer;
}

input,
textarea {
    @include placeholder {
        opacity: 1;
    }
}

/*-- Common Classes --*/
.section,
.main-wrapper {
    // float: left;
    width: 100%;
}


/*--Section Spacing--*/
.section-padding {
    padding-bottom: 5rem;
    padding-top: 4rem;

    // Responsive
    @media #{$desktop-device} {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @media #{$tablet-device} {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    @media #{$large-mobile} {
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.section-padding-02 {
    padding-top: 120px;

    // Responsive
    @media #{$desktop-device} {
        padding-top: 100px;
    }

    @media #{$tablet-device} {
        padding-top: 80px;
    }

    @media #{$large-mobile} {
        padding-top: 60px;
    }
}

.section-padding-03 {
    padding-top: 140px;

    // Responsive
    @media #{$desktop-device} {
        padding-top: 100px;
    }

    @media #{$tablet-device} {
        padding-top: 80px;
    }

    @media #{$large-mobile} {
        padding-top: 60px;
    }
}


/*--Section Title--*/
.section-title {
    // max-width: 690px;
    // margin: 0 auto;

    & .title {
        font-size: 44px;
        line-height: 54px;
        font-family: $heading-font;
        font-weight: 500;
        color: $heading-color;

        @media #{$large-mobile} {
            font-size: 30px;
            line-height: 36px;
        }

        &.white {
            color: $white;
        }
    }

    & .sub-title {
        font-size: 14px;
        line-height: 30px;
        font-family: $body-font;
        font-weight: 500;
        color: $primary-2;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-bottom: 12px;

        &.color-2 {
            color: $primary-2;
        }

        &.color-3 {
            color: $primary-3;
        }

        &.white {
            color: $white;
        }
    }

    & .contnet {
        margin-top: 20px;
        font-size: 18px;
        line-height: 26px;
    }


}


/*--Section Title Two--*/
.section-title2 {
    max-width: 690px;
    margin: 0 auto;

    & .title {
        font-size: 48px;
        line-height: 54px;
        font-family: $heading-font;
        font-weight: 800;
        color: $heading-color1;

        & span {
            color: $primary;
        }

        @media #{$desktop-device} {
            font-size: 36px;
            line-height: 48px;
        }

        @media #{$large-mobile} {
            font-size: 28px;
            line-height: 36px;
        }

    }

    & .sub-title {
        font-size: 12px;
        line-height: 30px;
        font-family: $heading-font;
        font-weight: 800;
        color: #a1a1a1;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    & p {
        font-size: 16px;
        line-height: 30px;
        font-family: $heading-font;
        font-weight: 600;
        color: #4c4d56;
        padding: 0 50px;
        margin-top: 20px;
    }

}


/*--Swiper Pagination--*/
.swiper-pagination {
    & .swiper-pagination-bullet {
        border-radius: 0% !important;
        background-color: #e8e8e8;
        width: 60px;
        height: 3px;
        transition: $transition-default;
        position: relative;
        opacity: 1;
        margin-right: 10px;

        &::after {
            content: "";
            position: absolute;
            left: -7px;
            top: -7px;
            // border: 1px solid #57585a;
            transform: scale(0);
            width: calc(100% + 14px);
            height: calc(100% + 14px);
            // border-radius: 50%;
            transition: $transition-default;
        }

        &.swiper-pagination-bullet-active {
            background-color: $primary;

            &::after {
                transform: scale(1);
            }
        }
    }
}



/*--  Preloader Styles  --*/
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    z-index: 999999;

    & .preloader {
        width: 50px;
        height: 50px;
        display: inline-block;
        padding: 0px;
        text-align: left;
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;

        & span {
            position: absolute;
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: $primary;
            animation: preloader 1.3s linear infinite;

            &:last-child {
                animation-delay: -0.8s;
            }
        }
    }
}

@keyframes preloader {
    0% {
        transform: scale(0, 0);
        opacity: 0.5;
    }

    100% {
        transform: scale(1, 1);
        opacity: 0;
    }
}

.ck-powered-by {
    display: none !important;
}


.ServiceImage{
    border-radius: 15px;
    width: 40%;
    margin-top: 10px;
}
.SnowImage{
    border-radius: 15px;
    height:50px;
    margin-top: 10px;
}