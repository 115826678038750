/*--
/*  03 - Solution CSS
/*----------------------------------------*/

.solution-content-wrap {
    padding-top: 40px;
}

.solution-item {
    position: relative;
    margin-top: 28px;

    & .solution-img {

        & a {
            // border-radius: 10px;
            overflow: hidden;
            position: relative;
            display: block;

            &::before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, black 62%);
                transition: all 0.3s linear;
                opacity: 0.6;
                z-index: 1;
            }

            & img {
                width: 100%;
            }
        }
    }

    & .solution-content {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 40px 110px 120px 85px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 2;

        @media #{$desktop-device} {
            padding: 40px 60px 90px 60px;
        }

        @media #{$large-mobile} {
            padding: 40px 35px 70px 40px;
        }

        @media #{$extra-small-mobile} {
            padding: 40px 35px 45px 35px;
        }

        & .solution-title {
            flex: 1;

            & .sub-title {
                font-size: 14px;
                line-height: 27px;
                font-weight: 500;
                color: $white;
            }

            & .title {
                font-size: 24px;
                line-height: 27px;
                font-weight: 600;
                color: $white;
                margin-top: 10px;

                @media #{$small-mobile} {
                    font-size: 20px;
                }
            }
        }

        & .play-btn {
            width: 80px;
            height: 80px;
            line-height: 80px;
            text-align: center;
            background: $white;
            color: $primary;
            border-radius: 50%;

            &:hover {
                background: $primary;
                color: $white;
            }
        }
    }

    &.solution-item-big {
        & .solution-content {

            & .solution-title {
                padding-right: 110px;

                @media #{$large-mobile} {
                    padding-right: 0px;
                }
            }
        }
    }

    &.solution-item-sm {
        & .solution-content {
            padding: 40px 45px 45px 55px;

            @media #{$desktop-device} {
                padding: 35px 25px 30px 30px;
            }

            @media #{$tablet-device} {
                padding: 40px 45px 45px 55px;
            }

            @media #{$extra-small-mobile} {
                padding: 5px 30px 25px 25px;
            }

            & .solution-title {
                padding-right: 50px;

                @media #{$extra-small-mobile} {
                    padding-right: 0px;
                }
            }
        }
    }
}