.blog-list-section {
    scroll-margin-top: 50px;

    .blog-list-wrapper {
        .blog-card {
            &.border--top {
                padding: 35px 0;
                border-top: 1px solid #E9E9E9;
            }

            .blog-card-img-wrap {
                position: relative;
                overflow: hidden;

                .card-img {
                    border-radius: 5px;
                    position: relative;
                    overflow: hidden;
                    display: block;
                    border-radius: 10px;
                    transition: all .5s ease-out;
                    max-height: 270px;
                    overflow: hidden;

                    img {
                        border-radius: 5px;
                        width: 100%;
                    }

                    &:after {
                        position: absolute;
                        width: 200%;
                        height: 0;
                        left: 50%;
                        top: 50%;
                        background-color: hsla(0, 0%, 100%, .3);
                        transform: translate(-50%, -50%) rotate(-45deg);
                        content: "";
                        z-index: 1;
                    }
                }

                .date {
                    position: absolute;
                    top: 35px;
                    left: 30px;
                    text-align: center;
                    width: 65px;
                    height: 65px;
                    border-radius: 5px;
                    background: linear-gradient(-160deg, rgb(181, 211, 54) 0%, rgb(81, 187, 126) 100%);
                    -webkit-backdrop-filter: blur(22.5px);
                    backdrop-filter: blur(22.5px);
                    transition: .5s;
                    z-index: 9;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        color: $secondary;
                        text-align: center;
                        font-size: 11px;
                        font-weight: 500;
                        line-height: 1;
                        letter-spacing: .22px;
                        display: block;

                        strong {
                            font-size: 22px;
                            font-weight: 700;
                            margin-bottom: 5px;
                            display: block;
                        }
                    }
                }
            }

            .blog-card-content-wrap {
                display: block;
                padding-left: 60px;

                .blog-card-content {
                    max-width: 550px;
                    margin-bottom: 45px;

                    h4 {
                        margin-bottom: 15px;

                        a {
                            color: $secondary;
                            font-size: 27px;
                            font-weight: 600;
                            line-height: 1.4;
                            transition: .5s;
                        }
                    }

                    p {
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 1.6;
                        margin-bottom: 0;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }

                    .button-area {
                        .read-more-btn {
                            min-width: unset;
                            max-width: unset;
                            height: unset;
                            border-radius: unset;
                            border: unset;
                        }
                    }

                    .blog-meta {
                        margin-bottom: 10px;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 10px;

                        .category {
                            padding: 0;
                            margin: 0;
                            list-style: none;
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            gap: 10px;

                            li {
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 1;
                                position: relative;

                                a {
                                    font-size: 13px;
                                    font-weight: 500;
                                    line-height: 1;
                                    text-decoration: underline;
                                    transition: .5s;
                                }
                            }
                        }
                    }

                }
            }

            &:hover {
                .blog-card-img-wrap {
                    .card-img {
                        img {
                            transform: scale(1.1);
                        }

                        &:after {
                            height: 250%;
                            transition: all .6s linear;
                            background-color: transparent
                        }
                    }

                    .date {
                        background: $primary-2;

                        span {
                            color: $white;

                        }
                    }

                }

            }
        }
    }
}