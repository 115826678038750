/*--
/*  06 - Skill CSS
/*----------------------------------------*/

.seven-skill-section {

    & .skill-left {
        padding-right: 120px;

        & p {
            font-size: 16px;
            line-height: 30px;
            margin-top: 40px;
        }

        & .skill-author {
            margin-top: 30px;

            & .name {
                font-size: 16px;
                line-height: 24px;
                font-weight: 600;
                margin-top: 10px;
            }

            & .designation {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }

    & .skill-right {
        padding-right: 50px;
        margin-top: 30px;

        & .experience {
            display: flex;

            & .number {
                font-size: 180px;
                line-height: 30px;
                color: $primary-2;
                margin-right: 10px;
            }

            & span {
                font-size: 18px;
                line-height: 24px;
                color: #333333;
                margin-top: 5px;
            }
        }
    }
}




// Counter Bar
.counter-bar {
    padding-top: 20px;

    & .skill-item {
        margin-top: 30px;

        & .title {
            font-size: 14px;
            line-height: 45px;
            font-family: $heading-font;
            font-weight: 600;
            color: #120e1d;
        }

        & .skill-bar {

            & .bar-inner {
                width: 100%;
                height: 6px;
                border-radius: 0px;
                background-color: transparent;
                position: relative;

                & .progress-line {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 6px;
                    border-radius: 0px;
                    background: $primary-2;
                    transition: 3s;
                    transition-delay: 1s;
                    width: 0;

                    & .skill-percent {
                        position: absolute;
                        top: -45px;
                        right: -30px;
                        font-size: 12px;
                        color: $white;

                        &::after {
                            content: "";
                            position: absolute;
                            left: -7px;
                            top: -2px;
                            width: 40px;
                            height: 25px;
                            background: $heading-color;
                            line-height: 20px;
                            text-align: center;
                            z-index: -1;
                        }

                        &::before {
                            position: absolute;
                            content: "";
                            border-top: 20px solid $heading-color;
                            width: 0;
                            height: 0;
                            bottom: -12px;
                            left: 0;
                            border-right: 15px solid transparent;
                            transform: translateX(-50%);
                            z-index: -1;
                        }
                    }
                }
            }
        }
    }

}

.seven-skill-section {
    @media #{$desktop-device} {
        & .skill-left {
            padding-right: 30px;
        }

        & .skill-right {

            & .experience {

                & .number {
                    font-size: 150px;
                }
            }
        }
    }

    @media #{$tablet-device} {
        & .skill-left {
            padding-right: 0px;
            margin-bottom: 50px;
        }
    }

    @media #{$small-mobile} {
        & .skill-right {
            padding-right: 0px;
        }

        & .counter-bar {

            & .skill-item {

                & .skill-bar {

                    & .bar-inner {

                        & .progress-line {

                            & .skill-percent {
                                right: -15px;
                            }
                        }
                    }
                }
            }

        }
    }
}

// Home Style Three


.seven-skill-section-02 {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: 5;
    overflow: hidden;

    & .skill-left {
        padding-right: 25px;

        & .experience-wrap {
            display: flex;
            margin-top: 35px;

            & .experience {
                max-width: 170px;
                background: linear-gradient(-120deg, rgb(67, 186, 255) 0%, rgb(113, 65, 177) 100%);
                padding: 30px;

                & .number {
                    font-size: 100px;
                    line-height: 54px;
                    font-weight: 600;
                    color: $white;
                }

                & span {
                    font-size: 18px;
                    line-height: 24px;
                    margin-top: 30px;
                    display: inline-block;
                    color: $white;
                }
            }

            & .experience-text {
                flex: 1;
                margin-left: 55px;

                & p {
                    font-size: 16px;
                    line-height: 30px;
                    margin-top: 0;
                }

                & .learn-more {
                    font-size: 16px;
                    line-height: 30px;
                    font-weight: 600;
                    color: $primary;
                    margin-top: 20px;

                    & i {
                        position: relative;
                        top: 1px;
                        transition: $transition-default;
                    }

                    &:hover {

                        & i {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }

    & .skill-right {
        margin-top: 0;

        & .counter-bar {
            padding-top: 0px;

            & .skill-item {

                & .skill-bar {

                    & .bar-inner {

                        & .progress-line {
                            background: $primary;
                        }
                    }
                }
            }

        }
    }
}

.seven-skill-section-02 {
    @media #{$desktop-device} {
        & .skill-left {
            padding-right: 0;
        }
    }

    @media #{$tablet-device} {
        & .skill-right {
            padding-top: 20px;
        }
    }

    @media #{$small-mobile} {
        & .skill-left {

            & .experience-wrap {
                flex-direction: column;
                gap: 25px;

                & .experience-text {
                    margin-left: 0px;
                }
            }
        }
    }
}

// About Page Style
.seven-skill-section-03 {

    & .skill-left {
        padding-right: 60px;

        & .about-list {
            margin-top: 20px;
        }

        & .about-author-info-wrap {
            margin-right: 30px;
        }
    }

    & .skill-right {
        padding-right: 120px;

        & .text {
            font-size: 18px;
            line-height: 30px;
            padding-bottom: 30px;
        }
    }
}

.seven-skill-section-03 {
    @media #{$desktop-device} {
        & .skill-left {
            padding-right: 30px;
        }

        & .skill-right {
            padding-right: 0;
        }
    }

    @media #{$tablet-device} {
        & .skill-left {
            padding-right: 0px;

            & .about-author-info-wrap {
                margin-right: 0px;
            }
        }
    }
}