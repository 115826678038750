/*--
/*  16 - Blog CSS
/*----------------------------------------*/

// HomePage

.home-blog-section {
    // background: #010f31;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    .home-blog-section-wrap {
        padding-top: 65px;

        .blog-card {
            .blog-card-img-wrap {
                position: relative;
                overflow: hidden;

                .card-img {
                    position: relative;
                    overflow: hidden;
                    display: block;
                    transition: all .5s ease-out;
                    max-height: 280px;

                    img {
                        transition: all .5s ease-out;
                        width: 100%;
                    }

                    &::after {
                        position: absolute;
                        width: 200%;
                        height: 0;
                        left: 50%;
                        top: 50%;
                        background-color: hsla(0, 0%, 100%, .3);
                        transform: translate(-50%, -50%) rotate(-45deg);
                        content: "";
                        z-index: 1;
                    }
                }

                .date {
                    color: $white;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1;
                    display: inline-flex;
                    align-items: center;
                    gap: 5px;
                    padding: 10px 16px;
                    background-color: $secondary;
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: 1;
                }
            }

            .card-content {
                padding-top: 15px;
                margin: 0 20px;

                .blog-meta {
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 10px;

                    .category {
                        padding: 0;
                        margin: 0;
                        list-style: none;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 10px;
                    }
                }

                h4 {
                    margin-bottom: 20px;

                    a {
                        color: $secondary;
                        font-size: 25px;
                        font-weight: 600;
                        line-height: 1.4;
                        transition: .5s;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;

                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }

            &:hover {
                .blog-card-img-wrap {
                    .card-img {
                        img {
                            transform: scale(1.1);
                        }

                        &::after {
                            height: 250%;
                            transition: all .6s linear;
                            background-color: transparent
                        }
                    }
                }
            }
        }
    }

    .swiper-pagination {
        bottom: 20px;
    }
}

.seven-blog-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.seven-blog-wrap {
    padding-top: 20px;
}

// Single Blog
.single-blog {
    margin-top: 30px;

    & .blog-image {
        position: relative;
        overflow: hidden;
        height: 440px;
        // border-radius: 10px;

        & a {
            display: block;

            & img {
                transition: $transition-default;
                width: 100%;
            }
        }

        & .top-meta {
            position: absolute;
            top: 35px;
            left: 30px;

            & .date {
                width: 65px;
                height: 65px;
                line-height: 65px;
                text-align: center;
                background: linear-gradient(-160deg, rgb(181, 211, 54) 0%, rgb(81, 187, 126) 100%);
                color: #fff;
                font-size: 14px;
                line-height: 18px;
                border-radius: 5px;
                font-family: $heading-font;
                display: inline-block;
                text-align: center;
                line-height: 12px;
                padding: 10px;

                & span {
                    font-size: 28px;
                    line-height: 18px;
                    font-weight: 500;
                    line-height: 30px;
                    display: block;
                }
            }
        }
    }

    & .blog-content {
        padding: 25px 30px 40px;
        background: $white;
        width: 90%;
        position: relative;
        left: 50%;
        bottom: 10px;
        transform: translateX(-48%);
        margin-top: -40px;

        @media #{$desktop-device} {
            padding: 25px 20px 40px;
            width: 95%;
        }

        & .blog-meta {

            & span {
                font-size: 13px;
                line-height: 24px;
                font-weight: 500;
                color: #999999;
                margin-right: 15px;

                @media #{$small-mobile} {
                    margin-right: 12px;
                }

                & i {
                    color: $primary;
                    margin-right: 5px;
                }
            }
        }

        & .title {
            font-size: 22px;
            line-height: 30px;
            font-weight: 600;
            color: $heading-color;
            padding-right: 15px;
            margin-top: 15px;

            @media #{$desktop-device} {
                padding-right: 0;
            }
        }
    }

    & .blog-btn {

        & .blog-btn-link {
            font-size: 12px;
            font-family: $heading-font;
            font-weight: 600;
            color: $primary;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-top: 15px;
            transition: $transition-default;

            & i {
                transition: $transition-default;
            }

            &:hover {
                & i {
                    margin-left: 5px;
                }
            }
        }
    }

    &:hover {
        & .blog-image {
            & a {
                & img {
                    transform: scale(1.1);
                }
            }
        }
    }
}

// Blog Page Style

.seven-blog-grid-wrap {
    margin-top: -30px;
}