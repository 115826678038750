/*--
/*  5.1 - Sidebar Widget CSS
/*----------------------------------------*/

// Sidebar Wrapper
.sidebar-wrap {
    border-left: 1px solid #eeeeee;
    height: 100%;
    @media #{$tablet-device, $large-mobile} {
        border-left: none;
        height: auto;
        padding-top: 0;
    }
}

// Widget
.widget {
    margin-bottom: 40px;
    color: $body-color;
    &:last-child {
        margin-bottom: 0;
    }
	& .widget-title {
		border-bottom: 1px solid #eeeeee;
		padding-left: 20px;
		padding-bottom: 20px;
		@media #{$tablet-device, $large-mobile} {
			padding-left: 0;
		}
	}
}



