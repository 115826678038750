/*--
/*  21 - Page Banner CSS
/*----------------------------------------*/

// Page Banner Section
.page-banner-section {
    padding-top: 85px;
    min-height: 470px;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
    overflow: hidden;
    z-index: 1;


    & .shape-1 {
        position: absolute;
        left: -25%;
        top: -75%;
        z-index: -1;
    }

    & .shape-2 {
        position: absolute;
        left: -20%;
        top: -65%;
        z-index: -1;
    }

    & .shape-3 {
        position: absolute;
        right: -10%;
        bottom: -50%;
        z-index: -1;
    }

    & .shape-4 {
        position: absolute;
        right: -26%;
        bottom: -135%;
        z-index: -1;
    }


}

.page-banner-section {
    @media #{$desktop-device} {
        min-height: 520px;

        & .shape-1 {
            left: -70%;
            top: -47%;
        }

        & .shape-2 {
            left: -30%;
            top: -55%;
        }

        & .shape-3 {
            right: -15%;
            bottom: -45%;
        }

        & .shape-4 {
            right: -50%;
            bottom: -130%;
        }
    }

    @media #{$tablet-device} {
        min-height: 450px;

        & .shape-1 {
            left: -95%;
            top: -58%;
        }

        & .shape-2 {
            left: -41%;
            top: -79%;
        }

        & .shape-3 {
            right: -25%;
            bottom: -60%;
        }

        & .shape-4 {
            right: -90%;
            bottom: -125%;
        }
    }

    @media #{$large-mobile} {
        min-height: 350px;

        & .shape-1 {
            display: none;
        }

        & .shape-2 {
            display: none;
        }

        & .shape-3 {
            display: none;
        }

        & .shape-4 {
            display: none;
        }
    }
}

// Page Banner
.page-banner {

    & .title {
        font-size: 60px;
        line-height: 1.25;
        font-family: $heading-font;
        display: inline-block;
        font-weight: 600;
        color: $white;
        position: relative;
        z-index: 1;

        @media #{$desktop-device} {
            font-size: 48px;
        }

        @media #{$large-mobile} {
            font-size: 30px;
        }
    }

    & .breadcrumb {
        margin-bottom: 0;
        margin-top: 10px;

        & .breadcrumb-item {
            color: $white;
            font-size: 20px;

            @media #{$tablet-device, $large-mobile} {
                font-size: 16px;
            }

            &+.breadcrumb-item {
                padding-left: 8px;

                &::before {
                    content: "/";
                    font-family: flaticon !important;
                    font-size: 20px;
                    padding-right: 5px;
                    color: $white;
                }
            }

            &.active {}

        }
    }
}