.single-form {
    margin-top: 25px;

    & input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]) {
        height: 55px;
        line-height: 55px;
        border: 1px solid #ebebeb;
        padding-left: 15px;

        @include placeholder {
            opacity: 0.95;
            font-size: 14px;
            color: #4c4d56;
            font-family: $heading-font;
            font-weight: 400;
        }
    }

    & select {
        height: 50px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid #ebebeb;
        padding-left: 15px;

        @include placeholder {
            opacity: 0.95;
            font-size: 14px;
            color: #4c4d56;
            font-family: $heading-font;
            font-weight: 400;
        }
    }

    & textarea {
        height: 85px;
        border: 1px solid #ebebeb;
        padding-left: 15px;
        resize: none;

        @include placeholder {
            opacity: 0.95;
            font-size: 14px;
            color: #4c4d56;
            font-family: $heading-font;
            font-weight: 400;
        }
    }
}



:root {
    --ck-z-default: 100;
    --ck-z-panel: calc(var(--ck-z-default) + 999);
}


.ck input.ck-input.ck-input-text {
    box-shadow: var(--ck-inner-shadow), 0 0;
    background: var(--ck-color-input-background);
    border: 1px solid var(--ck-color-input-border);
    padding: var(--ck-spacing-extra-tiny) var(--ck-spacing-medium);
    transition-property: box-shadow, border;
    transition: .2s ease-in-out;

    height: inherit;
    width: inherit;
    font-size: inherit;
    margin: 0;
    box-sizing: border-box;
}


.ck-editor__editable {
    border: 1px solid #cdcfd0;
    border-radius: 4px;
    height: 400px;
}

.ck input.ck-input.ck-input-text:focus {
    border: var(--ck-focus-ring);
    box-shadow: var(--ck-focus-outer-shadow), var(--ck-inner-shadow);
}


.popover-header {
    background-color: #EBF3EE !important;
    width: 104px;
}