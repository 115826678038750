/*--
/*  20 - career CSS
/*----------------------------------------*/

// career Section


.container {
    font-size: 18px;
    line-height: 28px;
    padding-top: 10px;
    font-family: "Barlow", sans-serif;
    .card-body {
      .content_details {
        position: relative;
        width: 100%;
      }
  
      .form-search-career {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
        height: 50px;
        color: #4c4d56;
        font-size: 16px;
        padding-left: 35px !important;
      }
      .form-search-career::placeholder {
        color: #4e4c4c !important;
        font-family: $heading-font !important;
        font-weight: 300 !important;
        font-size: 15px !important;
      }
  
      .search-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20px;
        pointer-events: none;
      }
  
      .form-select-career {
        color: #656669;
        height: 50px;
        width: 415px;
        @media #{$large-mobile} {
          height: 50px;
          width: 100%;
        }
  
        @media #{$small-mobile} {
          height: 50px;
          width: 100%;
        }
      }
      .form-input-career {
        color: #4c4d56;
        height: 50px;
        width: 415px !important;
        padding-left: 13px !important;
      }
      .form-input-career::placeholder {
        color: #4e4c4c !important;
        font-family: $heading-font !important;
        font-weight: 300 !important;
        font-size: 15px !important;
      }
    }
  }
  .section-careers {
    background-color: #f8f8f8;
  }
 .modal-content{
    border-radius: 0px !important;
      .job_content{
    // max-height: 600px;
    // overflow-y: scroll;
    border-radius: 0px !important;
    font-size: 17px;
    line-height: 28px;
padding: 25px;   
    font-family: "Barlow", sans-serif;
  }  
  

  }
  .applyButton{
    line-height: 36px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;

  }

