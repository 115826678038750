@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/swiper/swiper-bundle.min.css";
@import "../node_modules/aos/src/sass/aos.scss";
@import "../node_modules/swiper/swiper.scss";

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import 'scss/_variables';
@import 'scss/_common';
@import 'scss/_elements';
@import 'scss/_header';
@import 'scss/_pages';
@import 'scss/_widget';
@import 'scss/_footer';