/*--
/*  22 - Subscribe CSS
/*----------------------------------------*/


.seven-subscribe-section {

    & .subscribe-wrap {
        background: $primary-2;
        border-radius: 10px;
        padding: 40px 110px;
        padding-left: 40px;
        margin-bottom: -75px;
        position: relative;

        & .subscribe-content {
            display: flex;
            align-items: center;

            & .subscribe-icon {
                width: 115px;
                height: 115px;
                line-height: 110px;
                text-align: center;
                border-radius: 50%;
                position: absolute;
                top: -35px;
                left: 35px;
                background: $heading-color;
            }

            & .title {
                flex: 1;
                font-size: 34px;
                line-height: 36px;
                font-weight: 600;
                color: $white;
                padding-left: 160px;
            }
        }

        & .subscribe-form {

            & form {
                position: relative;

                & input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]):not([type="file"]) {
                    height: 60px;
                    line-height: 60px;
                    border: 0;
                    padding-right: 170px;

                    @include placeholder {
                        opacity: 0.95;
                        font-size: 18px;
                        color: #93a1a2;
                        font-weight: 400;
                    }

                    @media #{$large-mobile} {
                        height: 50px;
                        line-height: 50px;
                    }

                    @media #{$small-mobile} {
                        padding-right: 20px;

                        @include placeholder {
                            font-size: 16px;
                        }
                    }
                }

                & button {
                    height: 60px;
                    position: absolute;
                    right: 0;
                    top: 0;
                    border: 0;
                    background: $heading-color;
                    color: $white;
                    padding: 0 40px;
                    font-size: 16px;
                    font-family: $heading-font;
                    font-weight: 700;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;

                    @media #{$large-mobile} {
                        height: 50px;
                        line-height: 50px;
                    }

                    @media #{$small-mobile} {
                        position: relative;
                        width: 100%;
                        display: block;
                        margin-top: 15px;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }
                }
            }
        }
    }
}

.seven-subscribe-section {
    @media #{$desktop-device} {
        & .subscribe-wrap {

            & .subscribe-content {

                & .subscribe-icon {
                    width: 105px;
                    height: 105px;
                    line-height: 105px;
                }

                & .title {
                    padding-left: 90px;
                }
            }
        }
    }

    @media #{$tablet-device} {
        & .subscribe-wrap {
            text-align: center;
            padding: 40px 90px;

            & .subscribe-content {
                & .subscribe-icon {
                    left: 50%;
                    transform: translateX(-50%);
                }

                & .title {
                    padding-left: 0px;
                    margin-top: 40px;
                }
            }

            & .subscribe-form {
                margin-top: 30px;
            }
        }
    }

    @media #{$small-mobile} {
        & .subscribe-wrap {
            text-align: center;
            padding: 40px 30px;
        }
    }
}