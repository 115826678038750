// Transparent Header
.header-section {
    position: absolute;
    left: 0;
    top: 0;
    background: $white;
    width: 100%;
    z-index: 999;

    @media #{$tablet-device} {
        padding: 15px 0;
    }
}

// Header Wrap
.header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-wrap-admin {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// Header Logo 
.header-logo {
    & a {
        & img {
            width: 175px;

            @media #{$desktop-device} {
                width: 130px;
            }
        }
    }
}

// Header Menu 
.header-menu {

    & .main-menu {
        display: flex;

        & li {
            position: relative;
            padding: 30px 25px;

            @media #{$desktop-device} {
                padding: 30px 20px;
            }

            & a {
                font-size: 18px;
                font-family: $heading-font;
                font-weight: 500;
                text-transform: capitalize;
                color: $heading-color;
            }

            &.active-menu {

                &>a {
                    color: $primary;
                }

                &>.menu-icon {
                    &::after {
                        color: $primary;
                    }
                }
            }

            & .menu-icon {

                &::after {
                    content: '\f16c';
                    font-family: "flaticon";
                    font-size: 10px;
                    color: #94a0b9;
                    margin-left: 5px;
                    transition: $transition-default;
                }
            }

            &:hover {
                &>.menu-icon {
                    &::after {
                        color: $primary;
                    }
                }

                &>a {
                    color: $primary;
                }

                &>.sub-menu {
                    opacity: 1;
                    visibility: visible;
                    top: 100%;
                }
            }

            & .sub-menu {
                position: absolute;
                left: 0;
                top: 110%;
                width: 270px;
                background: $dark;
                box-shadow: 0 2px 29px rgba(0, 0, 0, .05);
                padding: 20px 0;
                // border-radius: 5px;
                border-top: 3px solid $primary;
                opacity: 0;
                z-index: 99;
                visibility: hidden;
                transition: $transition-default;

                & li {
                    padding: 0;

                    & a {
                        padding: 5px 25px;
                        font-size: 16px;
                        font-family: $heading-font;
                        font-weight: 500;
                        text-transform: capitalize;
                        display: block;
                        position: relative;
                        z-index: 1;
                        color: $white;
                        transition: all 0.3s linear;
                    }

                    .active {
                        color: $primary !important;
                    }

                    &:hover {

                        &>a {
                            padding-left: 30px;
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}

// Header Meta
.header-meta {
    display: flex;
    align-items: center;
    white-space: nowrap;
}


// Header Search
.header-search {
    margin-left: 25px;
    padding-left: 15px;
    position: relative;

    // &::before {
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     top: 50%;
    //     transform: translateY(-50%);
    //     background: #ebebeb;
    //     width: 1px;
    //     height: 30px;
    // }

    & .search-btn {
        font-size: 16px;
        border-radius: 50%;
        text-align: center;
        color: rgb(51, 51, 51);
        transition: $transition-default;

        &:hover {
            color: $primary;
        }
    }
}

// Header Search Wrap
.search-wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 121;
    background: rgba(0, 0, 0, 0.9);

    & .search-inner {
        position: relative;
        width: 100%;
        height: 100%;

        & .search-close {
            position: absolute;
            top: 50px;
            right: 50px;
            font-size: 22px;
            color: $white;
            cursor: pointer;
            transition: $transition-default;

            &:hover {
                transform: rotate(90deg);
                color: $primary;
            }
        }

        & .search-cell {
            position: absolute;
            top: 50%;
            width: 100%;
            transform: translateY(-50%);

            & .search-field-holder {
                width: 50%;
                margin: auto;
                position: relative;

                @media #{$tablet-device} {
                    width: 60%;
                }

                @media #{$small-mobile} {
                    width: 80%;
                }

                & .main-search-input {
                    width: 100%;
                    height: 70px;
                    border: 0;
                    padding: 0 50px;
                    text-transform: uppercase;
                    background: transparent;
                    font-size: 25px;
                    font-weight: 400;
                    color: $white;
                    border-bottom: 2px solid #898989;
                    text-align: center;
                    letter-spacing: 2px;

                    @media #{$desktop-device} {
                        padding: 0 35px;
                        font-size: 20px;
                    }

                    @media #{$large-mobile} {
                        font-size: 16px;
                        padding: 0 15px;
                    }

                    @include placeholder {
                        opacity: 0.95;
                        font-size: 25px;
                        color: $white;
                        font-weight: 400;

                        @media #{$desktop-device} {
                            font-size: 20px;
                        }

                        @media #{$large-mobile} {
                            font-size: 16px;
                        }

                        @media #{$small-mobile} {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

// Header Toggle
.header-toggle {
    margin-left: 30px;

    & button {
        background: none;
        border: 0;
        padding: 0;

        & span {
            width: 25px;
            height: 2px;
            background: $primary;
            margin: 5px 0;
            display: block;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

// Home Style Four
.header-section-menu {
    background: none;

    &.sticky {
        background: $secondary;
        border-bottom: 0;

        & .header-wrap {

            & .header-logo {
                // & .logo-black {
                //     display: block;
                // }

                & .logo-white {
                    display: block;
                }
            }

            & .header-menu {

                & .main-menu {

                    &>li {
                        padding: 15px 25px;

                        & a {
                            // color: $heading-color;
                        }

                        &.active-menu {

                            &>a {
                                // color: $primary;
                            }

                            &>.menu-icon {
                                &::after {
                                    // color: $primary;
                                }
                            }
                        }

                        & .menu-icon {

                            &::after {
                                color: #94a0b9;
                            }
                        }


                        &:hover {
                            &>.menu-icon {
                                &::after {
                                    color: $primary;
                                }
                            }

                            &>a {
                                color: $primary;
                            }
                        }

                        & .sub-menu {
                            border-top: 3px solid $primary;

                            & li {
                                a {
                                    color: $white;
                                    transition: all 0.3s linear;
                                }

                                &.active {
                                    &>a {
                                        color: $primary;
                                    }
                                }

                                &:hover {

                                    &>a {
                                        color: $primary;
                                        padding-left: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            & .header-cart {

                & .cart-btn {
                    color: #333333;

                    &:hover {
                        color: $primary;
                    }
                }
            }

            & .header-search {

                & .search-btn {
                    // color: #333333;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    & .header-logo {
        & a {
            img {
                width: 150px;
            }
        }

        & .logo-black {
            // display: none;
        }
    }

    & .header-menu {

        & .main-menu {
            &>li {

                &>a {
                    color: $white;

                    &.active {

                        color: $primary;

                        &>.menu-icon {
                            &::after {
                                color: $primary;
                            }
                        }
                    }
                }


                & .menu-icon {

                    &::after {
                        color: $white;
                    }
                }

                &:hover {
                    &>.menu-icon {
                        &::after {
                            color: $primary;
                        }
                    }

                    &>a {
                        color: $primary;
                    }
                }
            }

            &>.has-submenu {
                .active {

                    color: $white;
                }
            }
        }
    }

    & .header-cart {

        & .cart-btn {
            color: $white;

            &:hover {
                color: $primary;
            }

            & .count {
                background: $primary;
                color: $white;
            }
        }
    }

    & .header-search {

        & .search-btn {
            color: $white;

            &:hover {
                color: $primary;
            }
        }
    }

    & .dropdown-cart {

        & .cart-items {

            & .single-cart-item {

                & .item-content {

                    & .quantity {
                        color: $primary;
                    }
                }
            }
        }

        & .cart-total {

            & .value {
                color: $primary;
            }
        }

        & .cart-btns {

            & .btn {

                &.btn-3 {
                    background: $primary;
                    color: $white;
                    box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);

                    &:hover {
                        background: $white;
                        color: $primary;
                    }
                }
            }
        }
    }
}

// Sticky Header
.sticky {
    position: fixed;
    top: 0;
    box-shadow: 0px 0px 16px 0px rgba($black, 0.07);
    animation: sticky 1s;
}

@keyframes sticky {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}