/*--
/*  05 - About CSS
/*----------------------------------------*/

.seven-about-section {
    background: #0c0520;
    padding-top: 200px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow: hidden;

    & .shape-1 {
        position: absolute;
        width: 100%;
        height: 130px;
        right: 0;
        left: 0px;
        top: -30px;
        background: #ffffff;
        transform: skewY(-3deg);
        transform-origin: 0;
        z-index: 2;
    }

    & .about-img-wrap {
        position: relative;

        & .about-img {

            & img {
                border-radius: 10px;
            }

            &.about-img-big {
                padding-top: 80px;
            }

            &.about-img-sm {
                position: absolute;
                top: 0px;
                right: 0;

                & .shape-01 {
                    position: absolute;
                    width: 9px;
                    height: 235px;
                    background: $primary;
                    right: 10px;
                    bottom: -270px;
                }
            }
        }
    }

    & .about-content-wrap {
        padding-left: 100px;

        & .section-title {

            & .title {
                color: $white;
            }

            & .sub-title {
                color: $white;
            }

        }

        & p {
            font-size: 16px;
            line-height: 30px;
            color: $white;
            padding-right: 15px;
            margin-top: 20px;
        }

        & .play-btn {
            margin-top: 60px;

            & a {
                position: relative;

                & i {
                    width: 80px;
                    height: 80px;
                    line-height: 80px;
                    font-size: 15px;
                    display: inline-block;
                    text-align: center;
                    border-radius: 50%;
                    background: linear-gradient(240deg, rgb(8, 106, 216) 0%, rgb(66, 185, 255) 100%);
                    color: $white;
                    transition: $transition-default;
                }

                & span {
                    font-size: 24px;
                    font-family: $heading-font;
                    font-weight: 600;
                    color: $white;
                    margin-left: 30px;
                }

                &::before {
                    content: "";
                    border: 2px solid $white;
                    position: absolute;
                    z-index: 0;
                    left: 16%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    display: block;
                    width: 180px;
                    height: 180px;
                    border-radius: 50%;
                    animation: zoombig 3.25s linear infinite;
                    animation-delay: .8s
                }

                &::after {
                    content: "";
                    border: 2px solid $white;
                    position: absolute;
                    z-index: 0;
                    left: 16%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    display: block;
                    width: 180px;
                    height: 180px;
                    border-radius: 50%;
                    animation: zoombig 3.25s linear infinite;
                    animation-delay: 0s;
                }

                &:hover {

                    & i {
                        background: linear-gradient(180deg, rgb(8, 106, 216) 0%, rgb(66, 185, 255) 100%);
                    }
                }
            }
        }
    }
}




.seven-about-section {
    @media #{$desktop-device} {
        padding-top: 165px;

        & .shape-1 {
            top: -60px;
        }

        & .about-img-wrap {

            & .about-img {


                &.about-img-sm {
                    right: -25px;

                    & .shape-01 {
                        height: 190px;
                        bottom: -228px;
                    }
                }
            }
        }

        & .about-content-wrap {
            padding-left: 50px;
        }
    }

    @media #{$tablet-device} {
        padding-top: 135px;

        & .about-content-wrap {
            padding-left: 0px;
            margin-top: 50px;
        }
    }

    @media #{$small-mobile} {
        padding-top: 150px;

        & .about-img-wrap {

            & .about-img {

                &.about-img-big {
                    padding-top: 0px;
                }

                &.about-img-sm {
                    display: none;
                }
            }
        }
    }
}


@keyframes zoombig {
    0% {
        transform: translate(-50%, -50%) scale(0.5);
        opacity: 1;
        border-width: 3px;
    }

    40% {
        opacity: .5;
        border-width: 3px;
    }

    65% {
        border-width: 2px;
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
        border-width: 2px;
    }
}

// Home Style Two
.seven-about-section-03 {
    overflow: hidden;
    padding-bottom: 180px;
    background-color: #EBF3EE;

    & .about-content-03 {
        padding-right: 145px;

        & .text {
            font-size: 18px;
            line-height: 32px;
            margin-top: 20px;
        }

        & .about-quote {
            background: #fff6f5;
            padding: 40px 30px;
            padding-right: 85px;
            border-radius: 5px;
            margin-top: 30px;

            & .blockquote {
                padding-left: 60px;
                margin-bottom: 0;
                position: relative;

                &::before {
                    content: '\f115';
                    font-family: flaticon !important;
                    font-size: 40px;
                    line-height: 1;
                    margin-right: 5px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    color: $primary-2;
                }

                & p {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 30px;
                    color: #0e0e0e;
                }
            }

        }

        & .about-list-02 {
            padding-top: 20px;
            padding-right: 25px;

            & .about-list-item-02 {
                margin-top: 20px;

                & .title {
                    font-size: 18px;
                    line-height: 30px;
                    color: #231e32;

                    & i {
                        color: $primary-2;
                        margin-right: 7px;
                    }
                }

                P {
                    font-size: 16px;
                    line-height: 26px;
                    margin-top: 10px;
                }
            }
        }

        & .about-list-03 {
            border-top: 1px solid #e2e2e2;
            padding-right: 25px;
            margin-top: 20px;

            & .about-list-item-03 {
                padding-right: 40px;
                margin-top: 25px;

                & .title {
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 700;
                    color: #231e32;
                }

                & p {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }

    }

    & .about-img-warp-3 {
        height: 170%;
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -4rem 0;

        & .shape-1 {
            width: -webkit-fill-available;
            height: 100%;
            background: linear-gradient(-160deg, rgb(181, 211, 54) 0%, rgb(81, 187, 126) 100%);
            position: absolute;
            // left: 85px;
            // bottom: -50px;
            z-index: -1;
        }

        & .about-img {

            & img {
                border-radius: 10px;
            }
        }

        & .about-img-big {
            text-align: center;
            opacity: 0.5;
        }

        & .about-img-sm {
            position: absolute;
            left: 0px;
            bottom: -65px;
        }
    }
}

.seven-about-section-03 {
    @media #{$laptop-device} {
        & .about-content-03 {
            padding-right: 60px;
        }
    }

    @media #{$desktop-device} {
        padding-bottom: 100px;

        & .about-content-03 {
            padding-right: 40px;
        }
    }

    @media #{$tablet-device} {
        padding-bottom: 180px;

        & .about-content-03 {
            padding-right: 140px;
            margin-bottom: 60px;
        }
    }

    @media #{$large-mobile} {
        padding-bottom: 150px;

        & .about-content-03 {
            padding-right: 0px;
        }

        & .about-img-warp-3 {

            & .shape-1 {
                left: 25px;
            }
        }
    }

    @media #{$small-mobile} {
        padding-bottom: 70px;

        & .about-content-03 {

            & .about-quote {
                padding-right: 35px;
            }
        }

        & .about-img-warp-3 {

            & .shape-1 {
                display: none;
            }

            & .about-img-big {
                text-align: center;
            }

            & .about-img-sm {
                display: none;
            }
        }
    }
}

// Home Style Three
.seven-about-section-04 {
    & .about-content-wrap {
        & .text {
            font-size: 16px;
            line-height: 30px;
            margin-top: 30px;
        }
    }

    & .about-img-wrap {
        position: relative;

        & .about-img {

            & img {
                border-radius: 10px;
            }
        }

        & .about-img-big {
            text-align: center;
            opacity: 0.5;
        }

        & .about-img-sm {
            position: absolute;
            left: -15px;
            bottom: 35px;
            border: 8px solid $white;
            border-radius: 10px;
        }
    }
}

// Why Choose us section

.whychoose-section {
    .whychoose-section-text {
        padding-left: 50px;
    }

    .whyChoose-nav {

        .btn {
            background: $primary !important;
            border: none;
            padding: 5px 10px;

            &:disabled {
                background-color: rgba($primary, .2) !important;
            }

            .feather {
                stroke: $DarkFenceGreen;
            }

            &.btn-prev {
                border-radius: 20px 20px 0px 20px;
            }

            &.btn-next {
                border-radius: 20px 20px 20px 0px;
            }

        }
    }

    .whyChoose-slider {
        display: flex;
        overflow: hidden;
        height: 50vh;

        .whyChoose-slider-item {
            overflow: hidden;
            margin-right: 20px;
            position: relative;
            cursor: pointer;
            display: block;

            .whyChoose-slider-inner-top {
                padding: 20px;
                position: absolute;
                right: 0px;
                z-index: 99;
                width: 100%;
                text-align: right;

                a {
                    border-radius: 20px;
                    height: 36px;
                    width: 36px;
                    padding: 5px;
                    border: none;

                    .lucide {
                        stroke: $DarkFenceGreen;
                        width: 18px;
                    }

                    &:hover {
                        color: $primary;

                        .lucide {
                            stroke: $white;
                            size: 16px;
                        }
                    }
                }

            }

            .whyChoose-slider-img {
                position: relative;
                background-color: $black;
                height: 100%;

                &::before {
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 100%;
                }

                img {
                    height: 100%;
                    object-fit: cover;
                    opacity: 0.45;
                    width: 100%;
                }
            }

            .whyChoose-slider-text {
                width: 100%;
                transform: translateY(-80px);
                transition: 500ms all ease;
                padding: 0px 20px 0 25px;

                h3 {
                    opacity: 1;
                    font-size: 22px;
                    font-weight: 500;
                    transform: translateY(0px);
                    transition: 500ms all ease;

                    a {
                        text-decoration: none;
                        color: $white;
                    }
                }

                span {
                    opacity: 0;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 24px;
                    display: inline-block;
                    color: $white;
                }
            }

            &:hover {
                .ori-team-social {
                    bottom: 0;

                    a {
                        transform: translateY(0px);
                    }
                }

                .whyChoose-slider-text {
                    transform: translateY(-150px);

                    span {
                        opacity: 1;
                    }
                }
            }
        }
    }
}


// Why Choose us section


.play-btn-02 {
    position: absolute;
    left: 15%;
    top: 16%;

    & a {
        width: 64px;
        height: 64px;
        line-height: 64px;
        font-size: 15px;
        display: inline-block;
        text-align: center;
        position: relative;
        border-radius: 50%;
        background: linear-gradient(-160deg, rgb(181, 211, 54) 0%, rgb(81, 187, 126) 100%);
        color: $white;
        transition: $transition-default;

        &::before {
            content: "";
            border: 2px solid $primary;
            position: absolute;
            z-index: 0;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            animation: zoombig 3.25s linear infinite;
            animation-delay: .8s
        }

        &::after {
            content: "";
            border: 2px solid $primary;
            position: absolute;
            z-index: 0;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            animation: zoombig 3.25s linear infinite;
            animation-delay: 0s;
        }

        & i {}

        &:hover {
            background: linear-gradient(180deg, rgb(81, 187, 126) 0%, rgb(181, 211, 54) 100%);
        }
    }
}

.seven-about-section-04 {
    @media #{$desktop-device} {
        & .about-content-wrap {
            padding-right: 40px;
        }
    }

    @media #{$tablet-device} {
        & .about-img-wrap {
            margin-top: 60px;
        }

        & .play-btn-02 {
            left: 22%;
        }
    }

    @media #{$large-mobile} {
        & .play-btn-02 {
            left: 2%;
        }
    }

    @media #{$small-mobile} {
        & .about-content-wrap {
            padding-right: 0px;
        }

        & .about-img-wrap {
            position: relative;

            & .about-img-big {
                text-align: center;
            }

            & .about-img-sm {
                display: none;
            }
        }

        & .play-btn-02 {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

// About Page Style