// Blog Details Section
.blog-details-section {
    padding: 40px 0px;

    @media #{$small-mobile} {
        padding: 10px 0px;
    }

    & .blog-details-wrap {
        .blog-details-post {
            .blog-image {
                position: relative;
                height: 550px;
                overflow: hidden;
                border-radius: 5px;
                transition: all .5s ease-out;

                @media #{$small-mobile} {
                    height: 250px;
                }

                img {
                    width: 100%;
                }

                &:after {
                    position: absolute;
                    width: 200%;
                    height: 0;
                    left: 50%;
                    top: 50%;
                    background-color: hsla(0, 0%, 100%, .3);
                    transform: translate(-50%, -50%) rotate(-45deg);
                    content: "";
                    z-index: 1;
                }

                &:hover {
                    img {
                        transform: scale(1.1);
                    }

                    &:after {
                        height: 250%;
                        transition: all .6s linear;
                        background-color: transparent
                    }
                }

                .batch {
                    position: absolute;
                    top: 20px;
                    left: 15px;

                    span {
                        color: $secondary;
                        font-size: 18px;
                        font-style: italic;
                        font-weight: 500;
                        line-height: 1;
                        border-radius: 5px;
                        background: $primary;
                        padding: 6px 26px;
                    }
                }
            }

            .blog-details-author-meta {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 30px 0;
                border-bottom: 1px solid #EEEEEE;
                margin-bottom: 60px;
                gap: 15px;
                flex-wrap: wrap;

                @media #{$small-mobile} {
                    margin-bottom: 20px;
                }

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        color: $secondary;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 1;
                        letter-spacing: .3px;
                        margin-right: 50px;
                        position: relative;
                        transition: .25s ease;

                        &:before {
                            content: "";
                            position: absolute;
                            right: -30px;
                            top: 3px;
                            width: 2px;
                            height: 10px;
                            line-height: 5px;
                            background-color: $primary-2;
                            border-radius: 4px;
                        }

                        &:last-child {
                            margin-right: 0;

                            &:before {
                                display: none;

                            }
                        }
                    }
                }
            }

            .blog-content {
                margin: 0 80px;

                @media #{$small-mobile} {
                    margin: 0px;
                }

                .blog-content-text {

                    p {
                        font-size: 20px;
                        line-height: 32px;
                    }

                    a {
                        color: $primary-2;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

            }
        }
    }
}

// Blog Details Post
// .blog-details-post {
//     padding-right: 30px;

//     .single-blog-post {
//         margin-top: 40px;

//         &.single-blog {

//             & .blog-content {
//                 padding: 30px 0px 0px;
//                 width: 100%;
//                 left: 0;
//                 bottom: 0px;
//                 transform: translateX(0);
//                 margin-top: 0px;

//                 & .blog-meta {

//                     & .tag {
//                         font-size: 15px;
//                         line-height: 30px;
//                         font-weight: 500;
//                         color: $primary;
//                     }
//                 }

//                 & .title {
//                     font-size: 36px;
//                     line-height: 30px;
//                 }

//                 & p {
//                     font-size: 16px;
//                     line-height: 30px;
//                     margin-top: 20px;
//                 }
//             }
//         }
//     }

//     & .blog-details-content {

//         & .blog-quote {
//             background-color: none;
//             position: relative;
//             margin-left: 50px;
//             margin-top: 40px;

//             &::after {
//                 content: "";
//                 position: absolute;
//                 left: 0;
//                 top: 50%;
//                 transform: translateY(-50%);
//                 background: $primary;
//                 width: 6px;
//                 height: 105px;

//                 @media #{$small-mobile} {
//                     display: none;
//                 }
//             }

//             & .blockquote {
//                 padding-right: 125px;
//                 padding-left: 25px;
//                 margin-bottom: 0;
//                 position: relative;

//                 @media #{$desktop-device} {
//                     padding-right: 0;
//                 }

//                 @media #{$small-mobile} {
//                     margin-left: 0;
//                 }

//                 &:not(:first-child) {
//                     margin-top: 25px;
//                 }

//                 &:not(:last-child) {
//                     margin-bottom: 25px;
//                 }

//                 & p {
//                     display: inline;
//                     font-size: 18px;
//                     font-family: $heading-font;
//                     color: $heading-color1;
//                     line-height: 32px;
//                     font-weight: 600;
//                     margin-top: 0;
//                 }
//             }
//         }

//         & .blog-details-text {

//             & p {
//                 font-size: 16px;
//                 line-height: 30px;
//                 margin-top: 50px;
//             }
//         }

//         & .blog-details-tag-share {
//             display: flex;
//             flex-wrap: wrap;
//             justify-content: space-between;
//             align-items: center;
//             margin-top: 65px;
//             padding-top: 20px;
//             border-top: 1px solid #e3e3e5;

//             & .blog-details-tag {
//                 padding-top: 20px;

//                 & .sidebar-widget {
//                     margin-top: 0;
//                     display: flex;
//                     align-items: center;

//                     & .label {
//                         font-size: 14px;
//                         line-height: 30px;
//                         font-family: $heading-font;
//                         font-weight: 700;
//                         color: $heading-color1;
//                         margin-right: 10px;
//                     }

//                     & .sidebar-tag {

//                         & li {
//                             margin-bottom: 0px;
//                         }
//                     }
//                 }
//             }

//             & .blog-details-share {
//                 display: flex;
//                 align-items: center;
//                 padding-top: 20px;

//                 & li {
//                     display: inline-block;
//                     margin-right: 10px;

//                     &:last-child {
//                         margin-right: 0;
//                     }

//                     & a {
//                         display: inline-block;
//                         font-size: 18px;
//                         width: 40px;
//                         height: 40px;
//                         line-height: 40px;
//                         border: 1px solid #ededed;
//                         text-align: center;
//                         color: $primary;
//                         border-radius: 50%;

//                         &:hover {
//                             opacity: 0.9;
//                         }
//                     }
//                 }
//             }

//         }

//         & .seven-post-pagination {
//             display: flex;
//             flex-wrap: wrap;
//             background: $white;
//             box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.16);
//             padding: 10px 30px;
//             margin-top: 80px;
//             overflow: hidden;
//             position: relative;

//             @media #{$desktop-device} {
//                 padding: 10px 20px;
//             }

//             @media #{$small-mobile} {
//                 padding: 0 10px;
//             }

//             &::before {
//                 position: absolute;
//                 content: '';
//                 width: 1px;
//                 background-color: #e1e1e1;
//                 left: 50%;
//                 transform: translateX(-50%);
//                 top: 30px;
//                 bottom: 30px;

//                 @media #{$large-mobile} {
//                     width: auto;
//                     height: 1px;
//                     left: 30px;
//                     right: 30px;
//                     top: 50%;
//                     transform: translateY(-50%);
//                     bottom: auto;
//                 }
//             }

//             & .previous-post,
//             & .next-post {
//                 width: 50%;

//                 @media #{$large-mobile} {
//                     width: 100%;
//                 }
//             }

//             & .blog-pagination-post {
//                 display: flex;
//                 align-items: center;
//                 padding: 30px 0;

//                 @media #{$desktop-device} {
//                     padding: 20px 0;
//                 }

//                 & .post-thumb {
//                     flex-shrink: 0;

//                     & a {
//                         & img {
//                             border-radius: 10px;
//                             object-position: center;
//                             object-fit: cover;
//                         }

//                         & i {
//                             font-size: 14px;
//                             color: $primary;
//                             padding: 0 10px;
//                         }
//                     }
//                 }

//                 & .post-content {
//                     flex-grow: 1;
//                     padding: 0 20px;

//                     @media #{$desktop-device} {
//                         padding: 0 15px;
//                     }

//                     @media #{$small-mobile} {
//                         padding: 0 13px;
//                     }

//                     & .title {
//                         font-size: 18px;
//                         line-height: 22px;
//                         font-weight: 600;
//                         color: $heading-color;

//                         @media #{$desktop-device} {
//                             font-size: 15px;
//                         }
//                     }

//                     & .date {
//                         font-size: 12px;
//                         line-height: 22px;
//                         color: #838383;

//                         & i {
//                             color: $primary;
//                             margin-right: 6px;
//                         }
//                     }
//                 }
//             }

//         }

//         & .comment-wrap {

//             & .comment-form {
//                 margin-top: 50px;

//                 & .comment-title {
//                     font-size: 22px;
//                     line-height: 30px;
//                     color: $heading-color;
//                 }

//                 & p {
//                     font-size: 14px;
//                     line-height: 30px;
//                     color: #686f7a;
//                 }

//                 & .comment-form-wrap {
//                     padding-top: 10px;

//                     & .single-form {
//                         margin-top: 20px;

//                         & .form-control {
//                             border: 1px solid transparent;
//                             background: #f6f7f9;
//                             border-radius: 0;

//                             @include placeholder {
//                                 opacity: 0.95;
//                                 font-size: 13px;
//                                 font-family: $heading-font;
//                                 color: #9d9d9d;
//                                 font-weight: 400;
//                             }

//                             &:focus {
//                                 outline: none;
//                                 border-color: $primary;
//                             }
//                         }

//                         & textarea {
//                             &.form-control {
//                                 height: 135px;
//                                 padding-top: 15px;
//                                 font-size: 13px;
//                                 color: #415674;
//                                 font-weight: 600;
//                                 padding: 10px 25px;
//                                 resize: none;

//                                 &:focus {
//                                     outline: none;
//                                     box-shadow: none;
//                                 }
//                             }
//                         }

//                     }

//                     & .form-btn {

//                         & .btn {
//                             height: 40px;
//                             line-height: 38px;
//                             box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, 0.15);
//                             margin-top: 25px;
//                             font-size: 16px;
//                             padding: 0 40px;
//                         }
//                     }
//                 }
//             }
//         }

//     }
// }