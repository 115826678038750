/*--
/*  24.1 - Footer Widget CSS
/*----------------------------------------*/

// Footer Widget Area
.footer-widget-wrap {
    padding: 2rem 0;
}

// Footer Widget
.footer-widget {
    margin-top: 30px;
}

// Widget Info
.widget-info {
    // padding-top: 20px;

    & ul {


        & li {
            display: flex;
            align-items: baseline;
            flex-wrap: nowrap;
            margin-top: 10px;

            & .info-icon {

                & svg {
                    display: inline-block;
                    color: $primary;
                    margin-right: 10px;
                    margin-top: 2px;
                }
            }

            & .info-text {
                font-size: 18px;
                line-height: 24px;
                font-family: $heading-font;
                font-weight: 600;
                color: $whiteYellowShade;
            }
        }
    }
}

.footer-widget-about {
    margin-top: 15px;

    & p {
        font-size: 14px;
        line-height: 30px;
        color: $white;
        padding-right: 110px;
        margin-top: 20px;

        @media #{$laptop-device} {
            padding-right: 0;
        }
    }
}

// Widget Social
.footer-social {

    & .social {

        & li {
            display: inline-block;


            & a {
                width: 35px;
                height: 35px;
                // line-height: 37px;
                text-align: center;
                border-radius: 50%;
                font-size: 16px;
                padding: 3px;
                background: $primary;
                margin: 0px 2px;

                svg {
                    fill: $secondary;
                    width: 16px;
                    height: 16px;
                }

                &:hover {
                    color: $primary;

                    svg {
                        fill: $white;

                    }
                }
            }
        }
    }
}

// Widget Title
.footer-widget {
    & .footer-widget-title {
        font-size: 20px;
        font-family: $heading-font;
        font-weight: 600;
        color: $white;
    }
}


// Widget About
.widget-about {}

// Widget Link
.widget-link {
    // padding-top: 22px;

    & .link {
        & li {
            margin-top: 7px;

            & a {
                font-size: 16px;
                color: $footer-text;
                transition: $transition-default;

                &:hover {
                    color: $primary;
                    padding-left: 10px;
                }
            }
        }
    }
}

// Home Style Two

.footer-section-02 {

    & .footer-social {

        & .social {

            & li {


                & a {
                    color: $primary-2;

                    &:hover {
                        color: $primary-2;
                    }
                }
            }
        }
    }

    & .widget-link {
        padding-top: 22px;

        & .link {
            & li {

                & a {

                    &:hover {
                        color: $primary-2;
                    }
                }
            }
        }
    }

    & .widget-info {

        & ul {

            & li {

                & .info-icon {

                    & i {
                        color: $primary-2;
                    }
                }

                & .info-text {

                    & a {

                        &:hover {
                            color: $primary-2;
                        }
                    }
                }
            }
        }
    }
}

// Home Style Four

.footer-section-04 {

    & .footer-widget-about {

        & p {
            color: $heading-color;
        }
    }

    & .footer-widget {
        & .footer-widget-title {
            color: $heading-color;
        }
    }

    & .widget-link {
        padding-top: 22px;

        & .link {
            & li {

                & a {
                    color: $heading-color;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    & .widget-info {

        & ul {

            & li {

                & .info-text {
                    color: $heading-color;
                }
            }
        }
    }
}

// Home Style Six

.footer-section-05 {

    & .footer-social {

        & .social {

            & li {


                & a {
                    color: $primary-3;

                    &:hover {
                        color: $primary-3;
                    }
                }
            }
        }
    }

    & .widget-link {
        padding-top: 22px;

        & .link {
            & li {

                & a {

                    &:hover {
                        color: $primary-3;
                    }
                }
            }
        }
    }

    & .widget-info {

        & ul {

            & li {

                & .info-icon {

                    & i {
                        color: $primary-3;
                    }
                }

                & .info-text {

                    & a {

                        &:hover {
                            color: $primary-3;
                        }
                    }
                }
            }
        }
    }
}