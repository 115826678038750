/*--
/*  04 - Service CSS
/*----------------------------------------*/
.seven-services-section {
    .services-item {
        background: #D4F8E6;
        // box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.06);
        border-radius: 20px;
        padding: 40px 45px 40px;
        margin-top: 30px;
        position: relative;

        &.services-item-bg {
            background: transparent !important;
            box-shadow: none !important;
        }

        & .services-item-img {

            & img {
                transition: $transition-default;
            }
        }

        & .services-item-content {

            & .title {
                font-size: 22px;
                line-height: 30px;
                margin-top: 20px;
            }

            & p {
                font-size: 18px;
                line-height: 28px;
                padding-top: 10px;
                margin-bottom: 30px;

            }
        }

        &:hover {

            & .services-item-img {

                & img {
                    transform: scale(0.9);
                }
            }
        }
    }
}

.seven-whychoose-us-section {
    // background-color: #EBF3EE;

    .whychoose-us-wrap {
        .whychoose-us-item {
            background: $white;
            box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.06);
            border-radius: 10px;
            padding: 40px 45px 40px;
            margin-top: 30px;
            position: relative;

            & .whychoose-us-img {

                & img {
                    transition: $transition-default;
                }
            }

            & .whychoose-us-content {

                & .title {
                    font-size: 22px;
                    line-height: 30px;
                    margin-top: 20px;
                }

                & p {
                    font-size: 18px;
                    line-height: 28px;
                    padding-top: 10px;

                }
            }

            &:hover {

                & .whychoose-us-img {

                    & img {
                        transform: scale(0.9);
                    }
                }
            }
        }
    }
}

.seven-key-section {
    background-color: rgba(#D4F8E6, 0.3);

    .services-card {
        height: 100%;
        display: flex;
        gap: 20px;
        padding: 30px 25px;
        border-left: 1px solid rgba(#0A2E34, 0.2);
        border-bottom: 1px solid rgba(#0A2E34, 0.2);

        .icon {
            img {
                width: 54px;
                transition: all .5s ease-out;
            }

        }

        .services-content {
            margin-bottom: 15px;

            h3 {
                color: #0A2E34;
                font-size: 26px;
                font-weight: 700;
                margin-bottom: 2px;
                line-height: 1.3;
            }

            span {
                color: rgba(#0A2E34, .5);
                font-size: 16px;
                font-weight: 500;
                padding-left: 37px;
                line-height: 1;
                position: relative;

                &::after {
                    content: "";
                    height: 1px;
                    width: 30px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: rgba(#0A2E34, .5);
                }
            }

            p {
                font-size: 18px;
                font-weight: 400;
                line-height: 1.7;
                margin-bottom: 0;
                margin-top: 20px;
            }
        }

        &:hover {
            .icon {
                img {
                    transform: rotate(1turn)
                }
            }
        }
    }
}