/*--
/*  03 - Hero CSS
/*----------------------------------------*/

.seven-hero-section {
    background: #f0f0f0;
    overflow: hidden;
    position: relative;
    padding-top: 85px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    z-index: 1;

    & .shape-2 {
        position: absolute;
        width: 100%;
        height: 130px;
        right: 0;
        left: 0px;
        bottom: -130px;
        background: $white;
        transform: skewY(-3deg);
        transform-origin: 0;
        z-index: 2;
    }

    & .hero-content {

        & .sub-title {
            font-size: 18px;
            line-height: 30px;
            font-family: $body-font;
            font-weight: 600;
            letter-spacing: 1px;
            height: 45px;
            line-height: 43px;
            background: $white;
            color: #484848;
            padding-left: 10px;
            padding-right: 25px;
            display: inline-block;
            border-radius: 35px;
            margin-bottom: 10px;

            & span {
                display: inline-block;
                height: 35px;
                line-height: 33px;
                font-size: 16px;
                font-family: $heading-font;
                font-weight: 600;
                background: $primary;
                color: $white;
                border-radius: 35px;
                padding: 0 15px;
                margin-right: 10px;
            }
        }

        & .title {
            font-size: 72px;
            line-height: 84px;
            color: $heading-color;
            margin-top: 15px;
        }

        & p {
            font-size: 24px;
            line-height: 36px;
            color: #4c4d56;
            padding-right: 45px;
            margin-top: 30px;
        }

        & .hero-btn {

            & .btn {
                margin-top: 45px;
            }
        }
    }

    & .hero-images {

        & .images {
            text-align: center;
            position: relative;
            bottom: -50px;
        }
    }
}

.seven-hero-section {
    @media #{$laptop-device} {

        & .shape-2 {
            bottom: -110px;
        }

        & .hero-content {

            & .sub-title {
                padding-right: 20px;

                & span {
                    font-size: 14px;
                    padding: 0 14px;
                    margin-right: 5px;
                }
            }

            & .title {
                font-size: 60px;
                line-height: 72px;
            }
        }

    }

    @media #{$desktop-device} {
        & .hero-content {

            & .sub-title {
                font-size: 14px;
                padding-right: 15px;
            }

            & .title {
                font-size: 50px;
                line-height: 62px;
            }

            & p {
                padding-right: 0px;
            }
        }

    }

    @media #{$tablet-device} {
        & .shape-2 {
            transform: skewY(-4deg);
            bottom: -75px;
        }

        & .hero-content {
            padding-top: 70px;
        }

        & .hero-images {
            padding-top: 50px;
        }
    }

    @media #{$large-mobile} {
        & .hero-content {

            & .sub-title {
                font-size: 14px;
                padding-right: 15px;

                & span {
                    font-size: 14px;
                    padding: 0 14px;
                    margin-right: 5px;
                }
            }

            & .title {
                font-size: 40px;
                line-height: 52px;
            }

            & p {
                font-size: 18px;
            }
        }

        & .hero-images {
            padding-top: 50px;
        }
    }

    @media #{$small-mobile} {
        & .hero-images {

            & .images {
                bottom: -15px;
            }
        }
    }

    @media #{$extra-small-mobile} {
        & .hero-content {

            & .sub-title {
                font-size: 11px;

                & span {
                    font-size: 12px;
                    padding: 0 12px;
                }
            }
        }
    }
}

// Home Style Two

.seven-hero-section-02 {
    padding-top: 185px;
    padding-bottom: 310px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(#171a29, 0.3);
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    & .svg-shape {
        position: absolute;
        bottom: -17%;
        left: -26%;
        width: 100%;
    }

    & .hero-content {

        & .sub-title {
            font-size: 24px;
            line-height: 36px;
            font-family: $body-font;
            font-weight: 400;
            color: $white;
        }

        & .title {
            font-size: 48px;
            font-weight: 300;
            line-height: 90px;
            padding-left: 45px;
            margin-top: 30px;
            color: $white;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                background: $primary-2;
                height: 255px;
                width: 10px;
                transform: translateY(-50%);
            }
        }

        & .hero-play-btn {
            margin-top: 55px;

            & a {
                position: relative;

                &::before {
                    content: "";
                    border: 2px solid #ffffff;
                    position: absolute;
                    z-index: 0;
                    left: 14%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    display: block;
                    width: 110px;
                    height: 110px;
                    border-radius: 50%;
                }
            }

            & .play-btn {

                & i {
                    width: 80px;
                    height: 80px;
                    background: transparent;
                    display: inline-block;
                    line-height: 80px;
                    text-align: center;
                    font-size: 14px;
                    color: $primary-2;
                    background: $white;
                    border-radius: 50%;
                }
            }

            & span {
                font-size: 30px;
                font-weight: 600;
                color: $white;
                margin-left: 30px;
            }

        }

    }
}

.seven-hero-section-02 {
    @media #{$laptop-device} {

        & .hero-content {

            & .title {
                font-size: 70px;
                line-height: 80px;

                &::before {
                    height: 220px;
                }
            }
        }
    }

    @media #{$desktop-device} {
        padding-bottom: 260px;

        & .svg-shape {
            bottom: -16%;
        }

        & .hero-content {

            & .title {
                font-size: 58px;
                line-height: 70px;

                &::before {
                    height: 195px;
                }
            }
        }
    }

    @media #{$tablet-device} {

        & .svg-shape {
            left: -35%;
        }
    }

    @media #{$large-mobile} {
        padding-top: 150px;

        & .svg-shape {
            bottom: -18%;
            left: -55%;
        }

        & .hero-content {

            & .sub-title {
                font-size: 20px;
            }

            & .title {
                font-size: 48px;
                line-height: 60px;

                &::before {
                    height: 180px;
                }
            }
        }
    }

    @media #{$extra-small-mobile} {

        & .svg-shape {
            bottom: -18%;
            left: -55%;
        }

        & .hero-content {

            & .title {
                font-size: 35px;
                line-height: 45px;
                padding-left: 25px;

                &::before {
                    height: 140px;
                    width: 8px;
                }
            }

            & .hero-play-btn {

                & a {

                    &::before {
                        left: 13%;
                        width: 90px;
                        height: 90px;
                    }
                }

                & .play-btn {

                    & i {
                        width: 65px;
                        height: 65px;
                        line-height: 65px;
                    }
                }

                & span {
                    font-size: 25px;
                    font-weight: 600;
                    color: $white;
                    margin-left: 30px;
                }

            }
        }
    }
}

// Home Style Three

.seven-hero-section-03 {
    max-height: 100vh;
    // min-height: 100vh;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center center;
    overflow: hidden;
    position: relative;
    z-index: 1;

    & .shape-2 {
        position: absolute;
        left: 10%;
        top: -50%;
        z-index: 99;
    }

    & .shape-4 {
        position: absolute;
        top: 20%;
        right: -29%;
        z-index: 99;
    }

    & .hero-content {

        & .sub-title {
            font-size: 16px;
            line-height: 30px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $white;
        }

        & .title {
            font-size: 44px;
            line-height: 52px;
            font-weight: 400;
            color: $white;
            margin-top: 10px;
            text-align: left;
        }

        & p {
            font-size: 24px;
            line-height: 36px;
            font-weight: 500;
            color: $white;
            margin-top: 30px;
        }

        & .hero-btn {

            & .btn {
                margin-top: 40px;
            }
        }
    }

}

.seven-hero-section-03 {
    @media #{$laptop-device} {
        & .hero-content {

            & .title {
                font-size: 60px;
                line-height: 70px;
            }
        }
    }

    @media #{$desktop-device} {
        height: 850px;

        & .shape-1 {
            top: -65%;
            left: -20%;
        }

        & .shape-2 {
            left: 4%;
            top: -48%;
        }

        & .shape-3 {
            right: -16%;
            bottom: -26%;
        }

        & .shape-4 {
            top: 25%;
            right: -61%;
        }

        & .hero-content {

            & .title {
                font-size: 50px;
                line-height: 60px;
            }

            & p {
                font-size: 20px;
                line-height: 32px;
            }
        }
    }

    @media #{$tablet-device} {
        height: 400px;

        & .shape-1 {
            top: -79%;
            left: -30%;
        }

        & .shape-2 {
            left: -4%;
            top: -59%;
        }

        & .shape-4 {
            top: 17%;
            right: -73%;
        }
    }

    @media #{$large-mobile} {

        & .shape-1 {
            top: -85%;
            left: -45%;
        }

        & .shape-2 {
            left: -12%;
            top: -65%;
        }

        & .shape-3 {
            right: -30%;
            bottom: -35%;
        }

        & .shape-4 {
            display: none;
        }

        & .hero-content {

            & .title {
                font-size: 40px;
                line-height: 50px;
            }

            & p {
                font-size: 18px;
                line-height: 30px;
            }
        }
    }

    @media #{$small-mobile} {

        & .shape-1 {
            display: none;
        }

        & .shape-2 {
            display: none;
        }

        & .shape-3 {
            display: none;
        }

        & .hero-content {

            & .sub-title {
                font-size: 15px;
            }

            & .title {
                font-size: 32px;
                line-height: 42px;
            }
        }
    }
}

// Home Style Four

.seven-hero-section-04 {
    height: 1070px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(#2d2d4e, 0.5);
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    & .shape-1 {
        position: absolute;
        left: 0;
        top: 15%;
        z-index: -1;
    }

    & .shape-2 {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    & .shape-svg {
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        z-index: 1;

        & svg {
            fill: $white;
        }
    }

    & .hero-content {
        max-width: 710px;
        margin: 0 auto;

        & .sub-title {
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $white;
        }

        & .title {
            font-size: 150px;
            line-height: 78px;
            font-weight: 600;
            color: $white;
            margin-top: 45px;
        }

        & p {
            font-size: 24px;
            line-height: 36px;
            color: $white;
            margin-top: 70px;
        }

        & .hero-btn {

            & .btn {
                margin-top: 50px;
            }
        }
    }
}

.seven-hero-section-04 {
    @media #{$laptop-device} {
        height: 960px;

        & .hero-content {

            & .title {
                font-size: 130px;
            }
        }
    }

    @media #{$desktop-device} {
        height: 920px;

        & .hero-content {

            & .title {
                font-size: 110px;
            }
        }
    }

    @media #{$tablet-device} {
        height: 820px;
    }

    @media #{$large-mobile} {
        & .shape-1 {
            left: -5%;
            top: 15%;
        }

        & .shape-2 {
            right: -60px;
            bottom: -50px;
        }

        & .hero-content {

            & .sub-title {
                font-size: 18px;
            }

            & .title {
                font-size: 80px;
                margin-top: 30px;
            }

            & p {
                font-size: 18px;
                line-height: 32px;
                margin-top: 40px;
            }
        }
    }

    @media #{$small-mobile} {
        height: 760px;

        & .shape-1 {
            display: none;
        }

        & .shape-2 {
            display: none;
        }

        & .hero-content {

            & .sub-title {
                font-size: 16px;
            }

            & .title {
                font-size: 60px;
                line-height: 1;
            }
        }
    }
}

// Home Style Five

.seven-hero-section-05 {
    padding: 0 60px;
    margin-top: 90px;

    & .seven-hero-wrap {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        overflow: hidden;
        padding-top: 110px;
        padding-bottom: 150px;
        border-radius: 35px;

        & .hero-content {
            max-width: 710px;
            margin: 0 auto;

            & .sub-title {
                font-size: 14px;
                line-height: 30px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 1px;
                color: $white;
            }

            & .title {
                font-size: 70px;
                line-height: 78px;
                font-weight: 600;
                color: $white;
                margin-top: 10px;

                & span {
                    color: $primary;
                }
            }

            & p {
                font-size: 24px;
                line-height: 36px;
                color: $white;
                margin-top: 40px;
            }

            & .hero-btn {

                & .btn {
                    margin-top: 50px;
                }
            }
        }

        & .hero-images {

            & .images {
                animation: up-down 5s infinite;
            }
        }
    }
}

@keyframes up-down {
    0% {
        transform: translateY(30px);
    }

    50% {
        transform: translateY(0px);
    }

    100% {
        transform: translateY(30px);
    }
}

.seven-hero-section-05 {
    @media #{$desktop-device} {
        & .seven-hero-wrap {

            & .hero-content {

                & .title {
                    font-size: 50px;
                    line-height: 60px;
                }

                & p {
                    font-size: 20px;
                }
            }
        }
    }

    @media #{$tablet-device} {

        & .seven-hero-wrap {
            padding-top: 90px;
            padding-bottom: 120px;

            & .hero-images {

                & .images {
                    text-align: center;
                    margin-top: 60px;
                }
            }
        }
    }

    @media #{$large-mobile} {
        & .seven-hero-wrap {

            & .hero-content {

                & .title {
                    font-size: 40px;
                    line-height: 50px;
                }

                & p {
                    font-size: 18px;
                }
            }
        }
    }

    @media #{$small-mobile} {
        padding: 0 0;

        & .seven-hero-wrap {

            & .hero-content {

                & .title {
                    font-size: 36px;
                    line-height: 45px;
                }
            }
        }
    }
}

// Home Style Six

.seven-hero-section-06 {
    background: #0f0948;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding-top: 130px;

    & .hero-images {
        padding-top: 105px;
        position: relative;
        z-index: 1;

        & .shape-1 {
            position: absolute;
            left: 5%;
            top: 17%;
            animation: round-01 7s linear infinite;
            z-index: -1;
        }
    }

    & .hero-content {
        padding-top: 170px;

        & .sub-title {
            font-size: 14px;
            line-height: 30px;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: $white;
        }

        & .title {
            font-size: 70px;
            line-height: 78px;
            font-weight: 600;
            color: $white;
            margin-top: 10px;

            & span {
                color: $primary-3;
            }
        }

        & p {
            font-size: 24px;
            line-height: 36px;
            color: $white;
            margin-top: 40px;
        }

        & .hero-btn {

            & .btn-4 {
                height: 60px;
                line-height: 58px;
                padding: 0 65px;
                margin-top: 60px;
            }
        }
    }
}

@keyframes round-01 {
    0% {
        transform: rotate(15deg);
    }

    50% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(15deg);
    }
}

.seven-hero-section-06 {

    @media #{$laptop-device} {
        & .hero-images {

            & .shape-1 {
                left: 2%;
            }
        }

        & .hero-content {

            & .title {
                font-size: 60px;
                line-height: 70px;
            }
        }
    }

    @media #{$desktop-device} {
        & .hero-images {

            & .shape-1 {
                left: 2%;
            }
        }

        & .hero-content {
            padding-top: 120px;

            & .title {
                font-size: 50px;
                line-height: 60px;
            }
        }
    }

    @media #{$tablet-device} {
        & .hero-content {
            padding-top: 40px;
        }

        & .hero-images {

            & .shape-1 {
                left: 5%;
            }
        }
    }

    @media #{$large-mobile} {

        & .hero-images {
            padding-top: 80px;

            & .shape-1 {
                left: 0;
            }
        }

        & .hero-content {

            & .title {
                font-size: 40px;
                line-height: 50px;
            }

            & p {
                font-size: 20px;
                line-height: 32px;
            }
        }
    }

    @media #{$small-mobile} {

        & .hero-content {

            & .title {
                font-size: 32px;
                line-height: 42px;
            }
        }
    }
}

.heroBanner {
    width: 100%;
    // height: 850px;
    // position: absolute;
    // top: 0px;

    .swiper-pagination {
        // left: 40%;
        // right: 40%;
        // bottom: 50px;
    }

    .swiper-slide {
        font-size: 18px;
        // background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 900px;
    }


    @media #{$small-mobile} {

        & .swiper-slide {
            height: 400px;
        }
    }
}



.swiper-slide-img {
    position: absolute;
    // z-index: -1;
    // height: 100vw;
    width: 100%;

    img {
        height: 100%;

        display: block;
        // width: 100%;
        // height: 100%;
        object-fit: cover;
    }
}

.heroBanner-slide img {
    display: block;
    width: 100%;
    // height: 100%;
    object-fit: cover;
}

.autoplay-progress {
    position: absolute;
    left: 16px;
    bottom: 60px;
    z-index: 10;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--swiper-theme-color);
}

.autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: var(--swiper-theme-color);
    fill: none;
    stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
}